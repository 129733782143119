.c-button-group {
  display: inline-flex;
}

  .c-button-group > .c-button,
  .c-button-group > form {
    margin-left: 0 !important;
  }

  .c-button-group > :first-child,
  .c-button-group > :first-child > .c-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .c-button-group > :last-child,
  .c-button-group > :last-child > .c-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .c-button-group > :not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  .c-button-group > *:not(:last-child),
  .c-button-group > *:not(:last-child) > form > .c-button {
    margin-right: -1px;
  }

  .c-button-group > .c-label {
    border-radius: 0;
    display: inline-block;
  }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3Bpc3RhY2hpby9hcHAvY29tcG9uZW50cy9waXN0YWNoaW8vYnV0dG9uX2dyb3VwX2NvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxvQkFBb0I7QUFnQ3RCOztFQTlCRTs7SUFFRSx5QkFBeUI7RUFDM0I7O0VBRUE7O0lBRUUsMEJBQTBCO0lBQzFCLDZCQUE2QjtFQUMvQjs7RUFFQTs7SUFFRSx5QkFBeUI7SUFDekIsNEJBQTRCO0VBQzlCOztFQUVBO0lBQ0UsZ0JBQWdCO0VBQ2xCOztFQUVBOztJQUVFLGtCQUFrQjtFQUNwQjs7RUFFQTtJQUNFLGdCQUFnQjtJQUNoQixxQkFBcUI7RUFDdkIiLCJmaWxlIjoiLi4vcGlzdGFjaGlvL2FwcC9jb21wb25lbnRzL3Bpc3RhY2hpby9idXR0b25fZ3JvdXBfY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jLWJ1dHRvbi1ncm91cCB7XG4gIGRpc3BsYXk6IGlubGluZS1mbGV4O1xuXG4gID4gLmMtYnV0dG9uLFxuICA+IGZvcm0ge1xuICAgIG1hcmdpbi1sZWZ0OiAwICFpbXBvcnRhbnQ7XG4gIH1cblxuICA+IDpmaXJzdC1jaGlsZCxcbiAgPiA6Zmlyc3QtY2hpbGQgPiAuYy1idXR0b24ge1xuICAgIGJvcmRlci10b3AtcmlnaHQtcmFkaXVzOiAwO1xuICAgIGJvcmRlci1ib3R0b20tcmlnaHQtcmFkaXVzOiAwO1xuICB9XG5cbiAgPiA6bGFzdC1jaGlsZCxcbiAgPiA6bGFzdC1jaGlsZCA+IC5jLWJ1dHRvbiB7XG4gICAgYm9yZGVyLXRvcC1sZWZ0LXJhZGl1czogMDtcbiAgICBib3JkZXItYm90dG9tLWxlZnQtcmFkaXVzOiAwO1xuICB9XG5cbiAgPiA6bm90KDpmaXJzdC1jaGlsZCk6bm90KDpsYXN0LWNoaWxkKSB7XG4gICAgYm9yZGVyLXJhZGl1czogMDtcbiAgfVxuXG4gID4gKjpub3QoOmxhc3QtY2hpbGQpLFxuICA+ICo6bm90KDpsYXN0LWNoaWxkKSA+IGZvcm0gPiAuYy1idXR0b24ge1xuICAgIG1hcmdpbi1yaWdodDogLTFweDtcbiAgfVxuXG4gID4gLmMtbGFiZWwge1xuICAgIGJvcmRlci1yYWRpdXM6IDA7XG4gICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICB9XG59XG4iXX0= */