import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle } from 'pistachio'

export default class extends Controller {
  POLL_TIME_MILLISECONDS = 1000

  static targets = [
    'modal',
    'downloadLink',
  ]

  connect() {
    this.status = 'idle'
    this.update()
  }

  stop() {
    clearTimeout(this.pollingTimeout)
  }

  disconnect() {
    // make sure we stop polling
    this.stop()
  }

  onTrigger(event) {
    const path = this.element.dataset.path

    this.status = 'running'
    Helpers.emit(this.modalTarget, 'modal:open!')
    this.update()

    Helpers.ajax({
      type: this.element.dataset.triggerMethod || 'GET',
      url: this.element.dataset.triggerPath,
      success: (response) => {
        const token = response
        this.poll(token)
      },
    })
  }

  poll(token) {
    Helpers.ajax({
      type: 'GET',
      url: this.element.dataset.statusPath,
      data: `token=${token}`,
      success: (response) => {
        console.log(response)
        if (response.done) {
          this.status = 'done'
          this.downloadLinkTarget.href = response.url
          this.update()
        } else {
          this.pollingTimeout = setTimeout(() => this.poll(token), this.POLL_TIME_MILLISECONDS)
        }
      },
    })
  }

  update() {
    this.element.querySelectorAll('[data-status]').forEach(el => toggle(el, el.dataset.status === this.status))
  }
}
