import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'form',

    'step',

    'previousButton',
    'nextButton',
    'finishButton'
  ]

  connect() {
    this.currentStep = 0

    if(this.hasStepWithErrors) {
      this.currentStep = this.stepWithErrors
    }

    this.update()
  }

  onPreviousStep(event) {
    this.currentStep = Math.max(this.firstStep, this.currentStep - 1)

    this.update()
  }

  onNextStep(event) {
    if (!this.isFieldsValid(this.currentStep)) {
      return false
    }

    this.currentStep = Math.min(this.lastStep, this.currentStep + 1)
    this.update()

    event.preventDefault() // never submit
    return false
  }

  update() {
    this.stepTargets.forEach((step, index) => {
      toggle(step, index == this.currentStep)
    })

    toggle(this.previousButtonTarget, this.currentStep != this.firstStep)
    toggle(this.nextButtonTarget, this.currentStep != this.lastStep)
    toggle(this.finishButtonTarget, this.currentStep == this.lastStep)
  }

  isFieldsValid(step) {
    for (let input of this.stepTargets[step].querySelectorAll('input')) {
      if (!input.validity.valid) return false
    }

    return true
  }

  get firstStep() {
    return 0
  }

  get lastStep() {
    return this.stepTargets.length - 1
  }

  get form() {
    return this.element.querySelector('input').form
  }

  get stepWithErrors() {
    for (const [index, step] of this.stepTargets.entries()) {
      if(step.querySelectorAll('.invalid').length > 0) {
        return index
      }
    }
    return false
  }

  get hasStepWithErrors() {
    return this.stepWithErrors !== false
  }

}
