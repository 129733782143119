.c-button-group-select {
  display: inline-flex;
}

  .c-button-group-select > .c-button,
  .c-button-group-select > form {
    margin-left: 0 !important;
  }

  .c-button-group-select > :first-child,
  .c-button-group-select > :first-child > .c-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .c-button-group-select > :last-child,
  .c-button-group-select > :last-child > .c-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .c-button-group-select > :not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  .c-button-group-select > *:not(:last-child),
  .c-button-group-select > *:not(:last-child) > form > .c-button {
    margin-right: -1px;
  }

  .c-button-group-select > .c-label {
    border-radius: 0;
    display: inline-block;
  }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3Bpc3RhY2hpby9hcHAvY29tcG9uZW50cy9waXN0YWNoaW8vYnV0dG9uX2dyb3VwX3NlbGVjdF9jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Usb0JBQW9CO0FBZ0N0Qjs7RUE5QkU7O0lBRUUseUJBQXlCO0VBQzNCOztFQUVBOztJQUVFLDBCQUEwQjtJQUMxQiw2QkFBNkI7RUFDL0I7O0VBRUE7O0lBRUUseUJBQXlCO0lBQ3pCLDRCQUE0QjtFQUM5Qjs7RUFFQTtJQUNFLGdCQUFnQjtFQUNsQjs7RUFFQTs7SUFFRSxrQkFBa0I7RUFDcEI7O0VBRUE7SUFDRSxnQkFBZ0I7SUFDaEIscUJBQXFCO0VBQ3ZCIiwiZmlsZSI6Ii4uL3Bpc3RhY2hpby9hcHAvY29tcG9uZW50cy9waXN0YWNoaW8vYnV0dG9uX2dyb3VwX3NlbGVjdF9jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmMtYnV0dG9uLWdyb3VwLXNlbGVjdCB7XG4gIGRpc3BsYXk6IGlubGluZS1mbGV4O1xuXG4gID4gLmMtYnV0dG9uLFxuICA+IGZvcm0ge1xuICAgIG1hcmdpbi1sZWZ0OiAwICFpbXBvcnRhbnQ7XG4gIH1cblxuICA+IDpmaXJzdC1jaGlsZCxcbiAgPiA6Zmlyc3QtY2hpbGQgPiAuYy1idXR0b24ge1xuICAgIGJvcmRlci10b3AtcmlnaHQtcmFkaXVzOiAwO1xuICAgIGJvcmRlci1ib3R0b20tcmlnaHQtcmFkaXVzOiAwO1xuICB9XG5cbiAgPiA6bGFzdC1jaGlsZCxcbiAgPiA6bGFzdC1jaGlsZCA+IC5jLWJ1dHRvbiB7XG4gICAgYm9yZGVyLXRvcC1sZWZ0LXJhZGl1czogMDtcbiAgICBib3JkZXItYm90dG9tLWxlZnQtcmFkaXVzOiAwO1xuICB9XG5cbiAgPiA6bm90KDpmaXJzdC1jaGlsZCk6bm90KDpsYXN0LWNoaWxkKSB7XG4gICAgYm9yZGVyLXJhZGl1czogMDtcbiAgfVxuXG4gID4gKjpub3QoOmxhc3QtY2hpbGQpLFxuICA+ICo6bm90KDpsYXN0LWNoaWxkKSA+IGZvcm0gPiAuYy1idXR0b24ge1xuICAgIG1hcmdpbi1yaWdodDogLTFweDtcbiAgfVxuXG4gID4gLmMtbGFiZWwge1xuICAgIGJvcmRlci1yYWRpdXM6IDA7XG4gICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICB9XG59XG4iXX0= */