import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {

  connect() {
    this.changeHandler = this.onChange.bind(this)
    this.element.addEventListener('change', this.changeHandler)
    this.preview()
  }

  disconnect() {
    this.element.removeEventListener('change', this.changeHandler)
  }

  onChange(event) {
    // setTimout to delay in case we hit enter -> submit first, do not preview (race)
    setTimeout(() => this.preview(), 50)
  }

  preview() {
    // in case form is being submitted, skip preview
    // so it does not lead to race condition with overwriting flashes (session data)
    if (this.element.querySelector('input[type="submit"]:disabled')) return

    // abort previous request (always preview with most up-to-date form)
    if (this.abortController) { this.abortController.abort() }

    this.abortController = new AbortController()

    const request = new FetchRequest('post', this.element.dataset.formPreviewUrl, {
      body: Helpers.getFormDataForXHR(this.element),
      responseKind: 'turbo-stream',
      signal: this.abortController.signal,
    })

    // turbo stream is handled by request.js
    request.perform()
  }
}
