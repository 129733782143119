import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'loadingIndicator',
    'data',

    'report',
  ]

  connect() {
    this.element.querySelector('input[type="submit"]').click()
  }

  onTurboSubmitStart() {
    toggle(this.loadingIndicatorTarget, true)
    toggle(this.dataTarget, false)
  }

  onTurboBeforeFrameRender() {
    toggle(this.loadingIndicatorTarget, false)
    toggle(this.dataTarget, true)
  }

}
