import { Controller } from '@hotwired/stimulus'
import { DateUtils } from 'date_utils'
import { toggle } from 'pistachio'
import { Helpers } from 'pistachio'
import Glass from 'glass'

export default class extends Controller {
  static targets = [
    'actions',
    'addTimeLink',
    'startTimerButton',
    'stopTimerButton',
    'timeEntry',
    'timer',
    'dailySummary',
    'weekday',
    'currentWeekday',
    'weekdaysTotal',
    'todayChangedMessage',
  ]

  connect() {
    this.today = new Date().toDateString()

    if (!window.originalTitle) {
      window.originalTitle = document.title
    }

    this.updateDurationsInterval = setInterval(() => this.updateDurations(), 5000)
    this.updateDurations()
  }

  disconnect() {
    if (this.updateDurationsInterval) { clearInterval(this.updateDurationsInterval) }
  }

  onFocus(event) {
    const now = new Date().getTime()

    // only check if visibile
    if (document.visibilityState !== 'visible')
      return

    // prevent multiple events firing this event by time limiting the check
    if (this.lastCheckAt && (now - this.lastCheckAt) < 500)
      return

    this.lastCheckAt = now

    // NEW DAY?
    const date = new Date().toDateString()
    if (this.today && this.today != date) {
      console.log('today changed')
      toggle(this.todayChangedMessageTarget, true)
      Glass.track('time_sheet:today_changed')
    }

    // CHECKSUM -> TIMESHEET CHANGED?
    const oldChecksum = this.element.dataset.checksum
    const url = this.element.dataset.checksumUrl

    console.log('check checksum')
    Helpers.ajax({
      type: 'get',
      url: url,
      success: (response) => {
        const newChecksum = response.checksum
        console.log('old checksum', oldChecksum, 'new checksum', newChecksum)
        if (oldChecksum && newChecksum && oldChecksum !== newChecksum) {
          Glass.track('time_sheet:checksum_changed')
          // checksum changed, reload timesheet
          Helpers.reload()
        }
      },
      error: (data, status, xhr) => {
        // ignore regular error handling, so we won't get any alerts
        // (except 401 -> login form)
        if (xhr.status !== 401) return false
      }
    })
  }

  updateDurations() {
    this.updateTimerDuration()
    this.updateDailyDuration()
    this.updateWeeklyDuration()
  }

  updateTimerDuration() {
    this.timerTargets.forEach(t => {
      const timerElement = t.querySelector('[data-timer-started-at]')
      const timerStartedAt = new Date(timerElement.getAttribute('data-timer-started-at'))

      const elapsedSeconds = Math.max(new Date() - timerStartedAt, 0)/1000.0

      let seconds = null

      const startTime = timerElement.getAttribute('data-start-time')
      const endDateTime = DateUtils.parseTimeString(startTime)
      endDateTime.setMilliseconds(endDateTime.getMilliseconds() + elapsedSeconds * 1000)
      const endTime = DateUtils.formatHHMM(endDateTime)

      seconds = DateUtils.timeDiff(startTime, endTime)

      this.updateDuration(timerElement, seconds)
    })
  }

  updateDailyDuration() {
    if (!this.hasDailySummaryTarget) {
      return
    }

    let dailySeconds = 0
    let duration

    this.timeEntryTargets.forEach(e => {
      e.querySelectorAll('.duration').forEach(el => {
        dailySeconds += DateUtils.durationToSeconds(el.dataset.hhmmDuration)
      })
    })

    this.updateDuration(this.currentWeekdayTarget, dailySeconds)
    this.updateDuration(this.dailySummaryTarget, dailySeconds)
    this.updateIcon()

    document.title = `[${DateUtils.secondsToDuration(dailySeconds)}] ${window.originalTitle}`
  }

  updateWeeklyDuration() {
    let weeklySeconds = 0
    let duration

    this.weekdayTargets.forEach(e => {
      duration = e.querySelector('.duration').innerHTML
      weeklySeconds += DateUtils.durationToSeconds(duration)
    })

    if (this.hasWeekdaysTotalTarget) {
      this.updateDuration(this.weekdaysTotalTarget, weeklySeconds)
    }
  }

  updateDuration(element, seconds) {
    const durationElement = element.querySelector('.duration')
    const duration = seconds

    DateUtils.setDuration(durationElement, duration)
  }

  updateIcon() {
    const favIcon = document.querySelector('link[rel*="icon"]')
    if (!favIcon)
      return

    const defaultColor = 'blue'
    const activeColor = 'green'

    const color = this.isTimerRunning ? activeColor : defaultColor
    const iconHref = favIcon.getAttribute(`data-icon-${color}`)

    favIcon.href = iconHref
  }

  get isTimerRunning() {
    return this.hasTimerTarget
  }

  handleKeyPress(event) {
    // Don't bind if a modal is open
    const modal = document.querySelector('.c-modal')
    if (modal && window.getComputedStyle(modal).display !== 'none') {
      return false
    }

    // Make sure we don't catch keyboard events when in an input field
    if (['INPUT', 'TEXTAREA', 'SELECT'].includes(event.target.tagName)) {
      return false
    }

    switch (event.keyCode) {
      case 65: // a
        if(this.hasAddTimeLinkTarget) {
          this.addTimeLinkTarget.click()
        }
        break

      case 84: // t
        if (this.hasStopTimerButtonTarget) {
          this.stopTimerButtonTarget.click()
        } else {
          if(this.hasStartTimerButtonTarget) this.startTimerButtonTarget.click()
        }
        break
      default:
        return false
    }
  }
}
