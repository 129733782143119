import { Controller } from '@hotwired/stimulus'

import { toggle } from 'pistachio'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'workloadHidden',
    'workloadInput',

    'targetTimePerWeekAuto',
    'targetTimePerWeekAutoDuration',
    'targetTimePerWeekCustom',
    'customTargetTimePerWeekInput',
    'useCustomTargetTimePerWeekCheckbox',

    'vacationDaysPerYearAuto',
    'vacationDaysPerYearAutoDuration',
    'vacationDayWorthAutoDuration',
    'vacationDaysPerYearCustom',
    'customNumVacationDaysPerYearInput',
    'useCustomNumVacationDaysCheckbox',

    'vacationDayWorthInfo',
  ]

  static values = {
    calculationsUrl: String,
  }

  connect() {
    this.update()
    this.refreshCalcs()
  }

  onChange(event) {
    this.update()
    this.refreshCalcs()
  }

  refreshCalcs() {
    const allFormData = Helpers.getFormDataForXHR(this.element.closest('form'))
    const employmentData = new FormData()

    allFormData.entries().forEach(([key, value]) => {
      // normal form
      if (key.startsWith('employment')) {
        employmentData.append(key, value)
      }

      // nested form (accepts_nested_attributes_for)
      if (key.includes('[employments_attributes]')) {
        const newKey = key.replace(/.*?\[employments_attributes\]\[\d*\]/, 'employment')
        employmentData.append(newKey, value)
      }
    })

    Helpers.fetchAbortPrevious(this, {
      type: 'POST',
      url: this.calculationsUrlValue,
      data: employmentData,
      success: (data) => {
        this.targetTimePerWeekAutoDurationTarget.textContent = data.target_time_per_week
        if (!this.useCustomTargetTimePerWeekCheckboxTarget.checked) {
          this.customTargetTimePerWeekInputTarget.value = data.target_time_per_week
          this.customTargetTimePerWeekInputTarget.dispatchEvent(new Event('change', { bubbles: false }))
        }

        this.vacationDaysPerYearAutoDurationTargets.forEach((el) => el.textContent = data.num_vacation_days_per_year)
        this.vacationDayWorthAutoDurationTargets.forEach((el) => el.textContent = data.vacation_day_worth)
        if (!this.useCustomNumVacationDaysCheckboxTarget.checked) {
          this.customNumVacationDaysPerYearInputTarget.value = data.num_vacation_days_per_year
          this.customNumVacationDaysPerYearInputTarget.dispatchEvent(new Event('change', { bubbles: false }))
        }
      },
    })
  }

  update() {
    const useCustomTargetTimePerWeek = this.useCustomTargetTimePerWeekCheckboxTarget.checked
    this.targetTimePerWeekAutoTargets.forEach((el) => toggle(el, !useCustomTargetTimePerWeek, 'flex'))
    this.targetTimePerWeekCustomTargets.forEach((el) => toggle(el, useCustomTargetTimePerWeek, 'flex'))

    const useCustomNumVacationDays = this.useCustomNumVacationDaysCheckboxTarget.checked
    this.vacationDaysPerYearAutoTargets.forEach((el) => toggle(el, !useCustomNumVacationDays, 'flex'))
    this.vacationDaysPerYearCustomTargets.forEach((el) => toggle(el, useCustomNumVacationDays, 'flex'))

    // Employment type stuff
    if (this.employmentType == 'full_time') {
      this.workloadInputTarget.value = 100
    } else if(this.employmentType == 'hourly') {
      this.workloadInputTarget.value = 0
    }

    const isWorkloadRelevant = this.employmentType === 'part_time'
    this.workloadInputTarget.disabled = !isWorkloadRelevant

    // Submit workload even when text input is disabled
    this.workloadHiddenTarget.value = this.workloadInputTarget.value
  }

  get employmentType() {
    return this.element.querySelector('input[name*="employment_type"]:checked').value
  }
}
