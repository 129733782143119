import { Controller } from '@hotwired/stimulus'
import { show, hide, isVisible } from '../../../utils/visibility'
import { Helpers } from '../../../utils/helpers'

import {
  computePosition,
  flip,
  shift,
  autoUpdate,
  arrow,
  offset,
} from '@floating-ui/dom'

export default class extends Controller {
  connect() {
    this.popover = document.createElement('div')
    this.popover.className = 'c-popover dropdown'
    this.popover.innerHTML = `
      <div data-arrow></div>
      <div class="c-popover__content"></div>
    `
    document.body.appendChild(this.popover)


    this.element.addEventListener('click', (event) => {
      let popoverElement = event.target.closest('[data-popover]')

      if (popoverElement) {
        if (this.popover) {
          this.hide()
        }

        if (!this.popover || !isVisible(this.popover)) {
          this.show(popoverElement)
        }
      } else {
        // clicking outside hides
        if (this.popover && this.popover.style.display != 'none' && !this.popover.contains(event.target)) {
          this.hide()
        }
      }
    }, true)
  }

  disconnect() {
    this.popover.remove()
  }

  show(source) {
    this.cleanupPopover = autoUpdate(source, this.popover, () => {
      computePosition(source, this.popover, {
        placement: source.getAttribute('data-popover-position') || 'bottom',
        middleware: [
          flip(),
          offset(10),
          shift({ padding: 10 }),
          arrow({element: this.popover.querySelector('[data-arrow]')})
        ],
      })
      .then(({x, y, middlewareData, placement}) => {
        Object.assign(this.popover.style, {
          left: `${x}px`,
          top: `${y}px`,
        });

        this.popover.setAttribute('data-placement', placement)

        if (middlewareData.arrow) {
          const {x, y} = middlewareData.arrow;

          Object.assign(this.popover.querySelector('[data-arrow]').style, {
            left: x != null ? `${x}px` : '',
            top: y != null ? `${y}px` : '',
          });
        }
      })
    })

    let url = source.getAttribute('data-popover-url')

    this.reset()

    if (url) {
      this.loadContent(url)
    } else {
      this.setContent(source.getAttribute('data-popover'))
      show(this.popover)
    }

    // popover can be triggered by icon in a modal, make sure we set the z-index higher in this case
    if (this.isIndsideModal(source)) {
      this.popover.style.setProperty('z-index', this.getModalZIndex() + 1)
    }

    // event
    Helpers.emit(this.popover, 'popover:open', { source: source })
  }

  reset() {
    // abort pending remote loading content
    if (this.pendingRequest) {
      this.pendingRequest.abort()
      this.pendingRequest = null
    }

    // clear content
    this.setContent('')
  }

  setContent(content) {
    this.popover.querySelector('.c-popover__content').innerHTML = content
  }

  loadContent(url) {
    const request = new XMLHttpRequest()
    request.open('GET', url, true)
    request.setRequestHeader('Accept', 'text/html')
    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

    show(this.popover)
    this.setContent(`
      <div class="c-popover__loading">
        <i class="icon3000 icon-spinner-third animate-spin">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
            <path d="M457 372c11.5 6.6 26.3 2.7 31.8-9.3C503.7 330.2 512 294.1 512 256C512 122.7 410.1 13.2 280 1.1C266.8-.1 256 10.7 256 24v0c0 13.3 10.8 23.9 24 25.4C383.5 61.2 464 149.2 464 256c0 29.3-6.1 57.3-17 82.6c-5.3 12.2-1.5 26.8 10 33.5v0z"/>
          </svg>
        </i>
      </div>
    `)

    request.onload = () => {
      if (request.status !== 200) {
        hide()
        return
      }

      show(this.popover)
      this.setContent(request.responseText)
    }

    this.pendingRequest = request
    request.send()
  }

  hide() {
    hide(this.popover)
    if (this.cleanupPopover) {
      this.cleanupPopover()
    }
  }

  isIndsideModal(element) {
    if(element.closest('.c-modal')) {
      return true
    }
    return false
  }

  getModalZIndex() {
    const modal = document.querySelector('.c-modal')
    if (modal) {
      return parseInt(window.getComputedStyle(modal).getPropertyValue('z-index'))
    }
    return
  }
}
