import { Controller } from '@hotwired/stimulus'
import { Helpers } from '../../../../utils/helpers'

export default class extends Controller {
  static targets = [
    'modal',
    'content',
  ]

  connect() {
    this.element.addEventListener('click', (event) => {
      let el = event.target.closest('[data-remote-modal-url]')
      if (!el || !el.getAttribute)
        return

      const url = el.getAttribute('data-remote-modal-url')
      if (!url)
        return

      Helpers.emit(this.modalTarget, 'remote-modal:open', { source: el })

      event.stopPropagation()

      Helpers.emit(this.modalTarget, 'modal:open!', { preload: true })

      Helpers.ajax({
        type: 'GET',
        url: url,
        success: (response, statusText, xhr) => {
          Helpers.emit(this.modalTarget, 'modal:content!', xhr.responseText)
        },
        error: (response, statusText, xhr) => {
          Helpers.emit(this.modalTarget, 'modal:close!')
        },
      })
    }, true) // mousedown
  }
}
