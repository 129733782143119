import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'table',
  ]

  onRegionSet(event) {
    const url = this.element.dataset.tableUrl
    this.tableTarget.innerHTML = ''

    // using RemoteContentLoader ensures only the most recent region selection is requested, previous ones are aborted
    // (i.e. switching regions fast does not cause race condition where an earlier request overwrites a later request)
    Helpers.fetchAbortPrevious(this, {
      url: url,
      type: 'GET',
      data: `region=${event.detail.value}`,
      success: (response, status, xhr) => {
        this.tableTarget.innerHTML = xhr.responseText
      },
    })
  }
}
