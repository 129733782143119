import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  connect() {
  }

  onToggleForm(event) {
    const el = event.target.closest('tbody').querySelector('[data-form-url]')
    if (el) {
      Helpers.fetchAbortPrevious(this, {
        url: el.dataset.formUrl,
        type: 'GET',
        success: (data) => {
          el.outerHTML = data.body.innerHTML
        }
      })
    }
  }
}
