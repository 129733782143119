import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle, isVisible } from 'pistachio'

export default class extends Controller {
  static targets = [
    'batchActions',
    'toggleAll',
    'toggle',
  ]

  onToggleAll(event) {
    const check = event.target.checked
    this.toggleTargets.forEach(checkbox => checkbox.checked = check)
    this.update()
  }

  onToggle(event) {
    this.update()
  }

  update() {
    toggle(this.batchActionsTarget, this.toggleTargets.some(checkbox => checkbox.checked))

    if (this.hasToggleTarget) {
      this.toggleAllTarget.checked = this.toggleTargets.every(checkbox => checkbox.checked)
    }

    // toggle sexy selection border
    this.toggleTargets.forEach(checkbox => {
      checkbox.closest('tr').classList.toggle('selected', checkbox.checked)
    })
  }
}
