import { Controller } from '@hotwired/stimulus'
import { show } from 'pistachio'

export default class extends Controller {
  static targets = [
    'personalTokenInput',
    'organizationTokenInput',
  ]

  onCopyPersonalToken() {
    this.copyToClipboard(this.personalTokenInputTarget, this.personalTokenCopiedTarget)
  }

  onCopyOrganizationToken() {
    this.copyToClipboard(this.organizationTokenInputTarget, this.organizationTokenCopiedTarget)
  }

  copyToClipboard(input, message) {
    input.focus()
    input.select()
    document.execCommand('copy')
  }
}
