import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String,
  }

  static targets = [
    'rootURL',
    'absences',
    'publicHolidays',
    'weeklySchedules',
    'birthdays',
    'url',
    'copyButton'
  ]

  initialize() {
    this.updateLink()
  }

  updateLink() {
    const features = []
    if (this.absencesTarget.checked) {
      features.push('absences')
    }
    if (this.publicHolidaysTarget.checked) {
      features.push('public_holidays')
    }
    if (this.weeklySchedulesTarget.checked) {
      features.push('weekly_schedules')
    }
    if (this.birthdaysTarget.checked) {
      features.push('birthdays')
    }

    if (features.length > 0) {
      const url = new URL(this.urlValue)
      url.searchParams.append('features', features.join(','))

      this.urlTarget.value = url.toString()
      this.urlTarget.select()
    } else {
      this.urlTarget.value = ''
    }
  }

  copy() {
    this.urlTarget.select()
    document.execCommand('copy')
  }
}
