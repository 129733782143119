import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'title',
    'description',
    'type',
    'input',
    'button'
  ]

  connect() {
    this.inputTarget.focus()
    this.update()
  }

  update() {
    let selectedType = this.typeTargets.find((type) => type.checked)
    this.titleTarget.textContent = selectedType.dataset.title
    this.descriptionTarget.textContent = selectedType.dataset.description
    this.inputTarget.placeholder = selectedType.dataset.placeholder
    this.buttonTarget.textContent = selectedType.dataset.button
  }

  onTextEntered() {
    this.buttonTarget.disabled = this.inputTarget.value === ''
  }
}
