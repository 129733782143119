import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  connect() {
    this.handler = this.onAjaxError.bind(this)
    document.addEventListener('ajax:error', this.handler)
  }

  disconnect() {
    document.removeEventListener('ajax:error', this.handler)
  }

  // ajax:error is triggered via Helpers.ajax or Rails remote forms
  onAjaxError(event) {
    const [response, statusText, xhr] = event.detail

    // ignore error when xhr.abort() is called
    if (xhr.status === 0)
      return

    // assume 422 is handled (remote form handler)
    if (xhr.status === 422)
      return


    const message = `${xhr.status} ${xhr.statusText}`.trim()
    const authUrl = xhr.getResponseHeader('X-Auth-Url')

    if (window.Appsignal) {
      window.Appsignal.sendError(new Error(`XHR request failed (${message})`))
    }

    if (xhr.status === 401 && authUrl) {
      // delay visit so we get pending appsignal message
      setTimeout(() => Helpers.visit(authUrl), 500)
    } else {
      alert(`Error: ${message}. Please try again.`)
    }
  }
}
