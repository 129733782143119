import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'calendarDatePicker',
  ]

  connect() {
  }

  onToggleCalendar(event) {
    Helpers.emit(this.calendarDatePickerTarget, 'date:toggle!')
  }

  onCalendarDate(event) {
    const date = event.detail
    const url = this.element.dataset.calendarUrl.replace('DATE', date)
    Helpers.visit(url)
  }
}
