body.sm .c-selector-user-calendar__user {
    display: none;
}
  .c-selector-user-calendar__date-input {
    display: none;
  }
  body.lg .c-selector-user-calendar .flatpickr-calendar {
    top: 3rem !important;
    left: auto !important;
    right: 0px !important;
}
  body.sm .c-selector-user-calendar .flatpickr-calendar {
    top: 2rem !important;
}
  body.sm .c-selector-user-calendar .icon3000 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    margin-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
  body.sm .c-selector-user-calendar .c-button {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9jb21wb25lbnRzL3V0aWxzL3NlbGVjdG9yL3VzZXJfY2FsZW5kYXJfY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFSTtJQUFBO0FBQWdCO0VBR2xCO0lBQ0UsYUFBYTtFQUNmO0VBR0U7SUFBQSxvQkFBbUQ7SUFBbkQscUJBQW1EO0lBQW5EO0FBQW1EO0VBRW5EO0lBQUE7QUFBMEI7RUFJMUI7SUFBQSxrQkFBK0I7SUFBL0Isb0JBQStCO0lBQS9CLG9CQUErQjtJQUEvQixnREFBK0I7SUFDL0IsaUJBQThCO0lBQTlCLGdCQUE4QjtJQUE5QixtQkFBOEI7SUFBOUIsb0JBQThCO0lBQTlCO0FBRCtCO0VBSy9CO0lBQUEsa0JBQXFCO0lBQXJCO0FBQXFCIiwiZmlsZSI6ImFwcC9jb21wb25lbnRzL3V0aWxzL3NlbGVjdG9yL3VzZXJfY2FsZW5kYXJfY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jLXNlbGVjdG9yLXVzZXItY2FsZW5kYXIge1xuICAmX191c2VyIHtcbiAgICBAYXBwbHkgc206aGlkZGVuO1xuICB9XG5cbiAgJl9fZGF0ZS1pbnB1dCB7XG4gICAgZGlzcGxheTogbm9uZTtcbiAgfVxuXG4gIC5mbGF0cGlja3ItY2FsZW5kYXIge1xuICAgIEBhcHBseSBsZzp0b3AtMTIgbGc6bGVmdC1hdXRvIGxnOnJpZ2h0LTAgIWltcG9ydGFudDtcblxuICAgIEBhcHBseSBzbTp0b3AtOCAhaW1wb3J0YW50O1xuICB9XG5cbiAgLmljb24zMDAwIHtcbiAgICBAYXBwbHkgc206dGV4dC13aGl0ZSBzbTp0ZXh0LXhsO1xuICAgIEBhcHBseSBzbTptci0wIHNtOnB5LTAgc206cHgtMjtcbiAgfVxuXG4gIC5jLWJ1dHRvbiB7XG4gICAgQGFwcGx5IHNtOmJnLWJsdWUtNjAwO1xuICB9XG59XG4iXX0= */