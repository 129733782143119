import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  connect() {
    this.element.addEventListener('remote:fetch!', (event) => {
      const url = event.detail.url
      const callback = event.detail.callback

      this.load(event.target, url, callback)
    })

    this.element.addEventListener('click', (event) => {
      const source = event.target && event.target.closest('[data-remote-fetch-url]')
      if (!source)
        return

      const url = source.getAttribute('data-remote-fetch-url')
      const targetSelector = source.getAttribute('data-remote-fetch-target-selector')
      const target = targetSelector ? document.querySelector(targetSelector) : document.body
      if (!target)
        return

      this.load(target, url)

      event.stopPropagation()
      event.preventDefault()
    }, true)
  }

  load(target, url, callback) {
    target.style.opacity = '0.5'

    Helpers.fetchAbortPrevious(this, {
      type: 'GET',
      url: url,
      success: (response, status, xhr) => {
        target.outerHTML = xhr.responseText
        target.style.opacity = ''

        if (callback) callback(response, status, xhr)
      }
    })
  }
}
