import { Controller } from '@hotwired/stimulus'

import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'button',
    'modal',
    'numSelected',
  ]

  connect() {
    this.update()
  }

  onMarkAsPaid(event) {
    if (this.buttonTarget.classList.contains('disabled'))
      return

    Helpers.emit(this.modalTarget, 'modal:open!')
  }

  update() {
    const numSelected = this.element.querySelectorAll('input[name="expenses[]"]:checked').length
    const disabled = numSelected === 0

    this.buttonTargets.forEach(button => button.classList.toggle('disabled', disabled))

    if(this.hasNumSelectedTarget) {
      this.numSelectedTarget.innerText = numSelected
    }
  }
}
